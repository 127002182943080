import { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

export default function HomeLayout({ children }: Props) {
  return (
    <div className="min-h-screen flex flex-col pt-16">
      <main className="flex-grow pb-24">
        {children}
      </main>
    </div>
  )
}