import { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { lerp } from '@/utils/mouse'
import { projects, type Project } from '@/data/projects'
import { useIsMobile } from '@/hooks/use-mobile'

const ProjectGallery = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
  const [hoveredProject, setHoveredProject] = useState<Project | null>(null)
  const [touchedProject, setTouchedProject] = useState<Project | null>(null)
  const galleryRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLDivElement>(null)
  const [currentPosition, setCurrentPosition] = useState({ x: 0, y: 0 })
  const animationFrameRef = useRef<number>()
  const isMobile = useIsMobile()

  useEffect(() => {
    const animate = () => {
      if ((hoveredProject || touchedProject) && imageRef.current) {
        const newX = lerp(currentPosition.x, mousePosition.x, 0.1)
        const newY = lerp(currentPosition.y, mousePosition.y, 0.1)

        if (
          Math.abs(newX - currentPosition.x) > 0.01 ||
          Math.abs(newY - currentPosition.y) > 0.01
        ) {
          setCurrentPosition({ x: newX, y: newY })
        }
      }
      animationFrameRef.current = requestAnimationFrame(animate)
    }

    animate()
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current)
      }
    }
  }, [mousePosition, hoveredProject, touchedProject])

  useEffect(() => {
    if (imageRef.current) {
      if (isMobile) {
        // Center the image on mobile
        imageRef.current.style.transform = 'translate(-50%, -50%)'
        imageRef.current.style.left = '50%'
        imageRef.current.style.top = '50%'
      } else {
        imageRef.current.style.transform = `translate(${currentPosition.x}px, ${currentPosition.y}px)`
      }
    }
  }, [currentPosition, isMobile])

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isMobile && galleryRef.current) {
      const rect = galleryRef.current.getBoundingClientRect()
      setMousePosition({
        x: e.clientX - rect.left - (imageRef.current?.offsetWidth || 0) / 2,
        y: e.clientY - rect.top - (imageRef.current?.offsetHeight || 0) / 2,
      })
    }
  }

  const handleTouch = (project: Project) => {
    if (touchedProject?.id === project.id) {
      setTouchedProject(null)
    } else {
      setTouchedProject(project)
    }
  }

  return (
    <div className="min-h-screen bg-black dark:bg-zinc-900 py-20">
      <div className="container mx-auto px-4 max-w-6xl">
        <h2 className="text-4xl font-bold text-white mb-12 text-center">
          Featured Projects
        </h2>
        <p className="text-white/60 text-center mb-8">
          Hover over the projects or click to explore
        </p>
        <div ref={galleryRef} className="relative" onMouseMove={handleMouseMove}>
          <div className="space-y-4 md:space-y-8">
            {projects.map((project) => (
              <motion.div
                key={project.id}
                className="project-item relative cursor-pointer"
                onMouseEnter={() => !isMobile && setHoveredProject(project)}
                onMouseLeave={() => !isMobile && setHoveredProject(null)}
                onClick={() => isMobile && handleTouch(project)}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                <div className="border-t border-white/20 py-4 md:py-8 flex flex-col md:flex-row md:justify-between md:items-center gap-4">
                  <h3 className="text-3xl md:text-5xl font-medium text-white">
                    {project.title}
                  </h3>
                  <p className="text-white/60 text-base md:text-lg max-w-md">
                    {project.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>

          <motion.div
            ref={imageRef}
            className={`pointer-events-none fixed z-10 w-[280px] md:w-[500px] aspect-video rounded-lg bg-white/10 backdrop-blur-md ${
              isMobile ? 'top-1/2 left-1/2' : 'left-0 top-0'
            }`}
            initial={{ opacity: 0 }}
            animate={{
              opacity: hoveredProject || touchedProject ? 1 : 0,
            }}
            transition={{ duration: 0.3 }}
            style={{
              backgroundImage:
                hoveredProject || touchedProject
                  ? `url(${
                      (hoveredProject || touchedProject)?.imageUrl
                    })`
                  : 'none',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ProjectGallery