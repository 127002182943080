import { Link, useLocation } from 'react-router-dom'
import { useTheme } from 'next-themes'
import { useEffect, useState } from 'react'
import { Sun, Moon } from 'lucide-react'
import { motion } from 'framer-motion'

export default function Header() {
  const location = useLocation()
  const { theme, setTheme } = useTheme()
  const [mounted, setMounted] = useState(false)
  const currentPath = location.pathname

  const navigation = [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/about' }
  ]

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    return null
  }

  return (
    <header className="fixed top-0 z-50 w-full bg-transparent backdrop-blur-[2px]">
      <nav className="mx-auto flex h-16 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
        <Link 
          to="/" 
          className="relative text-xl font-bold text-gray-900 dark:text-white after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full after:origin-bottom-right after:scale-x-100 after:bg-gradient-to-r after:from-primary-500 after:to-blue-600 after:transition-transform after:duration-300"
        >
          p.s
        </Link>
        
        <div className="flex items-center space-x-8">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className={`${
                currentPath === item.href
                  ? 'text-primary-500 dark:text-primary-400'
                  : 'text-gray-700 hover:text-primary-500 dark:text-gray-300 dark:hover:text-primary-400'
              } text-sm font-medium transition-colors`}
            >
              {item.name}
            </Link>
          ))}
          <motion.button
            onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
            className="rounded-full p-2 text-gray-700 hover:bg-gray-100/10 dark:text-gray-300 dark:hover:bg-gray-800/10"
            aria-label="Toggle dark mode"
            whileTap={{ scale: 0.7, rotate: 360 }}
            transition={{ duration: 0.2 }}
            whileHover={{ scale: 1.2 }}
          >
            {theme === 'dark' ? <Sun size={20} /> : <Moon size={20} />}
          </motion.button>
        </div>
      </nav>
    </header>
  )
}